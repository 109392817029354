import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useDeviceInfo } from '../react-use';
export const useHover = (): [MutableRefObject<any>, boolean] => {
  const [isHovering, setIsHovering] = useState(false);
  const {
    device_isMobile,
    screen_isMobile
  } = useDeviceInfo();
  const nodeRef = useRef<any>();

  // const handleMouseOver = useCallback(() => {
  //   setIsHovering(screen_isDesktop ? true : false)
  // }, [])

  // const handleMouseOut = useCallback(() => {
  //   setIsHovering(() => false)
  // }, [])

  useEffect(() => {
    const handleMouseOver = () => {
      setIsHovering(() => !(device_isMobile || screen_isMobile) ? true : false);
    };
    const handleMouseOut = () => {
      setIsHovering(() => false);
    };
    const checkHover = (e: any) => {
      if (nodeRef.current) {
        const mouseOver = nodeRef.current.contains(e.target);
        if (mouseOver) {
          handleMouseOver();
        }
        if (!mouseOver) {
          handleMouseOut();
        }
      }
    };
    window.addEventListener('mousemove', checkHover, true);
    window.addEventListener('mouseout', checkHover, true);
    return () => {
      window.removeEventListener('mousemove', checkHover, true);
      window.removeEventListener('mouseout', checkHover, true);
    };
  }, [nodeRef.current]);

  // const callbackRef = useCallback(
  //   (node) => {
  //     if (nodeRef.current) {
  //       nodeRef.current.removeEventListener('mouseover', handleMouseOver)
  //       nodeRef.current.removeEventListener('mouseout', handleMouseOut)
  //     }

  //     nodeRef.current = node

  //     if (nodeRef.current) {
  //       nodeRef.current.addEventListener('mouseover', handleMouseOver)
  //       nodeRef.current.addEventListener('mouseout', handleMouseOut)
  //     }
  //   },
  //   [handleMouseOver, handleMouseOut]
  // )

  return [nodeRef, isHovering];
};