import _styled from "@emotion/styled/base";
function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import React from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
type DivProps = {
  $ratio: number;
};
const Div = _styled("div", process.env.NODE_ENV === "production" ? {
  target: "epx38hg0"
} : {
  target: "epx38hg0",
  label: "Div"
})("padding-bottom:", ({
  $ratio
}) => `${1 / $ratio * 100}%`, ";position:relative;height:0px;width:100%;;" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92YXIvbG9jby9sb2NvL2FwcHMvbG9jb2dnL3NyYy9jb21wb25lbnRzL2FzcGVjdFJhdGlvL2luZGV4LnRzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFRZ0MiLCJmaWxlIjoiL3Zhci9sb2NvL2xvY28vYXBwcy9sb2NvZ2cvc3JjL2NvbXBvbmVudHMvYXNwZWN0UmF0aW8vaW5kZXgudHN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHN0eWxlZCBmcm9tICdAZW1vdGlvbi9zdHlsZWQnXG5pbXBvcnQgUmVhY3QgZnJvbSAncmVhY3QnXG5pbXBvcnQgdHcgZnJvbSAndHdpbi5tYWNybydcblxudHlwZSBEaXZQcm9wcyA9IHtcbiAgJHJhdGlvOiBudW1iZXJcbn1cblxuY29uc3QgRGl2ID0gc3R5bGVkLmRpdjxEaXZQcm9wcz5gXG4gIHBhZGRpbmctYm90dG9tOiAkeyh7ICRyYXRpbyB9KSA9PiBgJHsoMSAvICRyYXRpbykgKiAxMDB9JWB9O1xuICAke3R3YHJlbGF0aXZlIGgtMCB3LWZ1bGxgfTtcbmBcblxuY29uc3QgQXNwZWN0UmF0aW8gPSAoe1xuICByYXRpbyxcbiAgY2hpbGRyZW4sXG4gIHN0eWxlUHJvcHMsXG59OiB7XG4gIHJhdGlvOiBudW1iZXJcbiAgY2hpbGRyZW46IFJlYWN0LlJlYWN0Tm9kZVxuICBzdHlsZVByb3BzPzogYW55XG59KSA9PiB7XG4gIHJldHVybiAoXG4gICAgPERpdiBjc3M9e3N0eWxlUHJvcHN9ICRyYXRpbz17cmF0aW99PlxuICAgICAgPGRpdiB0dz1cImFic29sdXRlIHRvcC0wIGxlZnQtMCBib3R0b20tMCByaWdodC0wXCI+e2NoaWxkcmVufTwvZGl2PlxuICAgIDwvRGl2PlxuICApXG59XG5cbmV4cG9ydCBkZWZhdWx0IEFzcGVjdFJhdGlvXG4iXX0= */"));
var _ref = process.env.NODE_ENV === "production" ? {
  name: "7dv867",
  styles: "position:absolute;top:0px;left:0px;bottom:0px;right:0px"
} : {
  name: "oepd0z-AspectRatio",
  styles: "position:absolute;top:0px;left:0px;bottom:0px;right:0px;label:AspectRatio;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const AspectRatio = ({
  ratio,
  children,
  styleProps
}: {
  ratio: number;
  children: React.ReactNode;
  styleProps?: any;
}) => {
  return _jsx(Div, {
    css: styleProps,
    $ratio: ratio,
    children: _jsx("div", {
      css: _ref,
      children: children
    })
  });
};
export default AspectRatio;